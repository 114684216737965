import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

import mainStyles from 'styles/mainStyles';
import Menu from 'components/Menu';
import Header from 'components/Header';
import UserList from 'components/UserList';
import UserEdit from 'components/UserEdit';
import VoucherList from 'components/VoucherList';
import VoucherAdd from 'components/VoucherAdd';
import VoucherSend from 'components/VoucherSend';
import GiftList from 'components/GiftList';
import GiftAdd from 'components/GiftAdd';
import GiftEdit from 'components/GiftEdit';
import GiftcodesList from 'components/GiftcodesList';
import CompanyList from 'components/CompanyList';
import CompanyAdd from 'components/CompanyAdd';
import CompanyEdit from 'components/CompanyEdit';
import NotificationsList from './NotificationsList';
import NotificationAdd from './NotificationAdd';
import NotificationEdit from './NotificationEdit';
import ScratchCardsList from './ScartchCardsList';
import ScratchCardAdd from './ScratchCardAdd';
import ScratchCardEdit from './ScratchCardEdit';
import StatsGifts from './StatsGifts';
import StatsUsers from './StatsUsers';
import CharitiesList from './CharitiesList';
import AddressExport from './AddressExport';

const MainContainer = () => {
	const classes = mainStyles();

	return (
		cookie.load('SESSION') ?
			<>
				<Header enableSecondary={false} />
				<div className={classes.appBackground} style={{ margin: 20 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container justifyContent={'flex-start'} alignItems={'flex-start'} spacing={3}>
								<Grid container item xs={3} xl={2}>
									<Paper elevation={3} className={classes.adminPaperContainerMenu}>
										<Menu />
									</Paper>
								</Grid>
								<Grid container item xs={9} xl={10}>
									<Switch>
										<Route path={'/users/list'}>
											<UserList />
										</Route>
										<Route path={'/users/edit/:uuid'}>
											<UserEdit />
										</Route>
										<Route path={'/gifts/list'}>
											<GiftList />
										</Route>
										<Route path={'/gifts/add'}>
											<GiftAdd />
										</Route>
										<Route path={'/gifts/:giftId/edit'}>
											<GiftEdit />
										</Route>
										<Route path={'/gifts/:giftId/giftcodes'}>
											<GiftcodesList />
										</Route>
										<Route path={'/companies/add'}>
											<CompanyAdd />
										</Route>
										<Route path={'/companies/edit/:uuid'}>
											<CompanyEdit />
										</Route>
										<Route path={'/companies/list'}>
											<CompanyList />
										</Route>
										<Route path={'/vouchers/list'}>
											<VoucherList />
										</Route>
										<Route path={'/vouchers/add'}>
											<VoucherAdd />
										</Route>
										<Route path={'/vouchers/send'}>
											<VoucherSend />
										</Route>
										<Route path={'/notifications/list'}>
											<NotificationsList />
										</Route>
										<Route path={'/notifications/add'}>
											<NotificationAdd />
										</Route>
										<Route path={'/notifications/edit/:uuid'}>
											<NotificationEdit />
										</Route>
										<Route path={'/scratchcards/list'}>
											<ScratchCardsList />
										</Route>
										<Route path={'/scratchcards/add'}>
											<ScratchCardAdd />
										</Route>
										<Route path={'/scratchcards/edit/:uuid'}>
											<ScratchCardEdit />
										</Route>
										<Route path={'/statsgifts/:userId'}>
											<StatsGifts />
										</Route>
										<Route path={'/statsgifts'}>
											<StatsGifts />
										</Route>
										<Route path={'/statsusers'}>
											<StatsUsers />
										</Route>
										<Route path={'/charities'}>
											<CharitiesList />
										</Route>
										<Route path={'/addresses'}>
											<AddressExport />
										</Route>
										<Route path={'/'}>
										</Route>
										<Redirect path={'*'} to={'/'}></Redirect>
									</Switch>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</>
			: <Redirect from={window.location.pathname} to={{
				pathname: '/login', state: {
					prevURL: window.location.pathname
				}
			}} />
	)
}

export default MainContainer;
