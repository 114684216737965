import { create } from 'apisauce';
import cookie from 'react-cookies';

const hostname = window && window.location && window.location.hostname;

let backendHost = 'https://djapi.webhouse.net';
if (hostname === 'localhost' || hostname === '192.168.1.110') {
	backendHost = 'http://127.0.0.1:3333';
}

const api = create({
	baseURL: backendHost,
	timeout: 30000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export const login = async (username, password) => {
	const data = api.post('/login', { username: username, password: password }).then(result => result.data);
	return data;
};

export const auth = async () => {
	const data = api.get('/auth').then(result => result.data);
	return data;
};

export const logout = async () => {
	const status = api.get('/logout').then(result => result.status);
	return status;
};

export const getLoggedInUser = async () => {
	let data = await api.get('/user').then(rs => rs.data);
	return data;
}

export const getUsers = async () => {
	let data = await api.get('/admin/users').then(rs => rs.data);
	return data;
}

export const getUser = async (userId) => {
	let data = await api.get('/admin/user/' + userId).then(rs => rs.data);
	return data;
}

export const updateUser = async (postData) => {
	let data = await api.put('/admin/user', postData).then(rs => rs.data);
	return data;
}

export const deleteUser = async (userId) => {
	let status = await api.delete('/admin/user/' + userId).then(rs => rs.status);
	return status;
}

export const confirmUser = async (userId) => {
	let status = await api.get('/admin/user/' + userId + '/confirm').then(rs => rs.status);
	return status;
}

export const exportUsers = async () => {
	let data = await api.get('/admin/export/users').then(rs => rs.data);
	return data;
}

export const exportUserStats = async () => {
	let data = await api.get('/admin/export/stats/users').then(rs => rs.data);
	return data;
}

export const getVouchers = async () => {
	let data = await api.get('/admin/vouchers').then(rs => rs.data);
	return data;
}

export const addVouchers = async (count, description) => {
	let data = await api.post('/admin/vouchers', { count: count, description: description }).then(rs => rs.data);
	return data;
}

export const deleteVoucher = async (uuid) => {
	let status = await api.delete('/admin/voucher/' + uuid).then(rs => rs.status);
	return status;
}

export const exportVouchers = async () => {
	let data = await api.get('/admin/export/vouchers').then(rs => rs.data);
	return data;
}

export const sendVouncerMail = async (file) => {
	let data = await api.post('/admin/voucher/send', { file: file }).then(rs => rs.data);
	return data;
}

export const getGifts = async () => {
	let data = await api.get('/admin/gifts').then(rs => rs.data);
	return data;
}

export const addGift = async (postData) => {
	let data = await api.post('/admin/gift', postData).then(rs => rs.data);
	return data;
}

export const getGift = async (giftId) => {
	let data = await api.get('/gift/' + giftId).then(rs => rs.data);
	return data;
}

export const updateGift = async (giftData) => {
	let data = await api.put('/admin/gift', giftData).then(rs => rs.data);
	return data;
}

export const deleteGift = async (giftId) => {
	let status = await api.delete('/admin/gift/' + giftId).then(rs => rs.status);
	return status;
}

export const getGiftCodes = async (giftId) => {
	let data = await api.get('/admin/giftcodes/' + giftId).then(rs => rs.data);
	return data;
}

export const exportGiftStats = async () => {
	let data = await api.get('/admin/export/stats/gifts').then(rs => rs.data);
	return data;
}

export const getCompany = async (uuid) => {
	let data = await api.get('/admin/company/' + uuid).then(rs => rs.data);
	return data;
}

export const addCompany = async (postData) => {
	let status = await api.post('/admin/company', postData).then(rs => rs.status);
	return status;
}

export const updateCompany = async (postData) => {
	let status = await api.put('/admin/company', postData).then(rs => rs.status);
	return status;
}

export const deleteCompany = async (uuid) => {
	let status = await api.delete('/admin/company/' + uuid).then(rs => rs.status);
	return status;
}

export const getCompanies = async () => {
	const data = api.get('/companies').then(result => result.data);
	return data;
};

export const getNotifications = async () => {
	const data = api.get('/admin/notifications').then(result => result.data);
	return data;
};

export const getNotification = async (uuid) => {
	const data = api.get('/admin/notification/' + uuid).then(result => result.data);
	return data;
};

export const addNotification = async (postData) => {
	const data = api.post('/admin/notification', postData).then(result => result.data);
	return data;
};

export const updateNotification = async (postData) => {
	const data = api.put('/admin/notification', postData).then(result => result.data);
	return data;
};

export const deleteNotification = async (uuid) => {
	const status = api.delete('/admin/notification/' + uuid).then(result => result.status);
	return status;
};

// SCRATCHCARDS

export const getScratchCards = async () => {
	const data = api.get('/admin/scratchcards').then(result => result.data);
	return data;
};

export const getScratchCard = async (uuid) => {
	const data = api.get('/admin/scratchcard/' + uuid).then(result => result.data);
	return data;
};

export const addScratchCard = async (postData) => {
	const data = api.post('/admin/scratchcard', postData).then(result => result.data);
	return data;
};

export const updateScratchCard = async (postData) => {
	const data = api.put('/admin/scratchcard', postData).then(result => result.data);
	return data;
};

export const deleteScratchCard = async (uuid) => {
	const status = api.delete('/admin/scratchcard/' + uuid).then(result => result.status);
	return status;
};

// STATS

export const getStats = async () => {
	const data = api.get('/admin/stats').then(result => result.data);
	return data;
};

export const getStatsForUser = async (userId) => {
	const data = api.get('/admin/stats/' + userId).then(result => result.data);
	return data;
};

// CHARITY

export const getCharities = async () => {
	const data = api.get('/charities').then(result => result.data);
	return data;
};

// ADDRESSES

export const exportAddresses = async (uuid) => {
	const result = api.get('/admin/export/address/' + uuid).then(result => result);
	return result;
}

export const setToken = () => {
	try {
		let token = cookie.load('SESSION').token;
		api.setHeader('Authorization', 'Bearer ' + token);

		return true;
	} catch (error) {
		return false;
	}
};

setToken();