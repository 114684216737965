import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, ButtonGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { getGifts, deleteGift } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const GiftList = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [gifts, setGifts] = useState(null);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const giftsData = await getGifts();

			//sort giftsData by door.day
			giftsData.sort((a, b) => {
				if (a.door.day < b.door.day) {
					return -1;
				}
				if (a.door.day > b.door.day) {
					return 1;
				}
				return 0;
			});		

			if (giftsData) {
				setGifts(giftsData);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const confirmDelete = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	};

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const status = await deleteGift(selectedUuid);

		if (status !== 200) {
			alert('Der opstod en fejl!');
		} else {
			setLoading(true);

			const data = await getGifts();

			if (data) {
				setGifts(data);
			}

			setLoading(false);

			setShowDeleteDialog(false);
			setSelectedUuid(null);
		}
	}

	const handleAddGift = () => {
		history.push('/gifts/add');
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Gaver</div>

			<Button variant="contained" color="primary" onClick={() => handleAddGift()} style={{ marginBottom: 20 }}>Tilføj gave</Button>

			{!loading ?
				<>
					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Tilknyttet firma</TableCell>
									<TableCell>Tilknyttet låge</TableCell>
									<TableCell>Beskrivelse</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{gifts.map(gift => {
									return <TableRow hover key={gift.giftId} className={classes.tableRow}>
										<TableCell>
											{gift.company !== null ? gift.company.name : 'Ingen tilknyttet'}
										</TableCell>
										<TableCell>
											{gift.door.day}
										</TableCell>
										<TableCell>
											{gift.description}
										</TableCell>
										<TableCell align="right">
											<ButtonGroup>
												<IconButton title="Rediger gave" onClick={() => history.push('/gifts/' + gift.giftId + '/edit')}>
													<EditIcon />
												</IconButton>
												<IconButton title="Vis gavekoder" onClick={() => history.push('/gifts/' + gift.giftId + '/giftcodes')}>
													<ListIcon />
												</IconButton>
												<IconButton title="Slet gave" onClick={() => confirmDelete(gift.giftId)}>
													<DeleteIcon />
												</IconButton>
											</ButtonGroup>
										</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</TableContainer>

					<Dialog
						disableEscapeKeyDown
						maxWidth="xs"
						open={showDeleteDialog}
					>
						<DialogTitle>Dette vil slette den valgte gave</DialogTitle>
						<DialogContent dividers>
							Er du sikker?
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={handleCancel} color="primary">
								Nej
							</Button>
							<Button onClick={handleOk} color="primary">
								Ja
							</Button>
						</DialogActions>
					</Dialog>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default GiftList;