import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import { getUser, updateUser } from 'data/api';
import CircularLoader from './ui/CircularLoader';

const UserEdit = (props) => {
	const classes = mainStyles();
	const history = useHistory();
	const { uuid } = useParams()

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [age, setAge] = useState('');
	const [sex, setSex] = useState('');
	const [zip, setZip] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		async function fetchData() {
			const data = await getUser(uuid);

			if (data) {
				setUser(data);
				setFirstname(data.firstname);
				setLastname(data.lastname);
				setEmail(data.email);
				setAge(data.age);
				setSex(data.sex);
				setZip(data.zip);

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	const handleBack = () => {
		history.push('/users/list');
	};

	const handleUpdate = async () => {
		const postData = { ...user };
		postData.firstname = firstname;
		postData.lastname = lastname;
		postData.email = email;
		postData.age = age;
		postData.sex = sex;
		postData.zip = zip;
		postData.password = password;

		const result = await updateUser(postData);

		if (!result) {
			alert('Der opstod en fejl');
		} else {
			history.push('/users/list');
		}
	};

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Rediger bruger</div>

			{!loading ?
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id={'firstname'}
							label='Fornavn'
							value={firstname}
							onChange={(e) => setFirstname(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'lastname'}
							label='Efternavn'
							value={lastname}
							onChange={(e) => setLastname(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'age'}
							label='Alder'
							value={age}
							onChange={(e) => setAge(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'sex'}
							label='Køn'
							value={sex}
							onChange={(e) => setSex(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'zip'}
							label='Postnr.'
							value={zip}
							onChange={(e) => setZip(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'password'}
							label='Ny adgangskode'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" onClick={() => handleBack()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
						<Button variant="contained" color="primary" onClick={() => handleUpdate()} style={{ marginTop: 30 }}>Godkend</Button>
					</Grid>
				</Grid>
				: <CircularLoader />}
		</Paper>
	);
}

export default UserEdit;