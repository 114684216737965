import { useRef, useState } from 'react';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { toBase64, validateEmail } from 'lib/functions';
import { sendVouncerMail } from 'data/api';
import ConfirmDialog from './ui/ConfirmDialog';

const VoucherSend = () => {
	const classes = mainStyles();
	const history = useHistory();

	let fileReader = null;

	const fileInput = useRef();

	const [confirmVisible, setConfirmVisible] = useState(false);
	const [file, setFile] = useState('');
	const [count, setCount] = useState(0);
	const [countError, setCountError] = useState(0);
	const [error, setError] = useState('');

	const back = () => {
		history.push('/vouchers/list');
	};

	const sendTapped = async () => {
		setError('');

		if (!file.length) {
			setError('Der skal uploades en fil');
		} else {
			setConfirmVisible(true);
		}
	}

	const handleSendCancel = () => {
		setConfirmVisible(false);
	}

	const doSend = async () => {
		const result = await sendVouncerMail(file);

		if (!result) {

		} else {
			setConfirmVisible(false);

			history.push('/vouchers/list');
		}
	};

	const handleFileRead = (e) => {
		const content = fileReader.result;

		const lines = content.split('\n');

		let tmpCount = 0;
		let tmpErrorCount = 0;
		for (let line of lines) {
			const lineParts = line.split(';');

			if (lineParts[0].length) {
				if (!validateEmail(lineParts[0])) {
					tmpErrorCount += 1;
				} else {
					tmpCount += 1;
				}
			}
		}

		setCountError(tmpErrorCount);
		setCount(tmpCount);
	};

	const handleUpload = async (event) => {
		setError('');

		if (event.target.files.length) {
			const logoBase64 = await toBase64(event.target.files[0]);
			setFile(logoBase64.replace('data:text/csv;base64,', ''));

			fileReader = new FileReader();
			fileReader.onloadend = handleFileRead;
			fileReader.readAsText(event.target.files[0]);
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Send voucher</div>
			<Grid container>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => fileInput.current.click()}
					>
						Upload CSV fil
					</Button>

					<input
						ref={fileInput}
						type="file"
						accept=".csv"
						hidden
						onChange={handleUpload}
					/>

					{count > 0 ? <div style={{ marginTop: 20 }}>{count} e-mails fundet</div> : null}
					{countError ? <div style={{ marginTop: 20 }}>{countError} invalid(e) e-mail(s) fundet</div> : null}

					{error.length ? <Typography color='error'>{error}</Typography> : null}
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={() => back()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
					<Button variant="contained" color="primary" onClick={() => sendTapped()} style={{ marginTop: 30 }}>Send</Button>
				</Grid>
			</Grid>

			<ConfirmDialog title='Dette vil afsende vouchers' text='Er du sikker?' visible={confirmVisible} handleCancel={handleSendCancel} handleOk={doSend} />
		</Paper>
	)
}

export default VoucherSend;