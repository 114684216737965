import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import 'moment/locale/da';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { getUsers, deleteUser, confirmUser, exportUsers } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const UserList = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [users, setUsers] = useState(null);
	const [usersFiltered, setUsersFiltered] = useState(null);
	const [searchstring, setSearchstring] = useState('');
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showValidateDialog, setShowValidateDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			const data = await getUsers();

			if (data) {
				setUsers(data);
				setUsersFiltered(data);
			}
		}

		fetchData();
	}, []);

	const renderState = (state) => {
		let text = 'Valideret';
		if (state === 2) {
			text = 'Ikke valideret';
		}
		return text;
	}

	const validateUser = async (uuid) => {
		setSelectedUuid(uuid);
		setShowValidateDialog(true);
	}

	const handleValidateOk = async () => {
		const status = await confirmUser(selectedUuid);

		if (status !== 200) {
			alert('Der opstod en fejl!');
		} else {
			const data = await getUsers();

			if (data) {
				setUsers(data);
			}

			setShowValidateDialog(false);
			setSelectedUuid(null);
		}
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
		setShowValidateDialog(false);
	}

	const confirmDelete = async (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	}

	const handleDeleteOk = async () => {
		const status = await deleteUser(selectedUuid);

		if (status !== 200) {
			alert('Der opstod en fejl!');
		} else {
			const data = await getUsers();

			if (data) {
				setUsers(data);
			}

			setShowDeleteDialog(false);
			setSelectedUuid(null);
		}
	}

	const _exportUsers = async () => {
		const fileDownload = require('js-file-download');

		const data = await exportUsers();

		if (data) {
			fileDownload(data, 'users.csv');
		}
	}

	const search = () => {
		const searchParts = searchstring.split(' ');
		let result = [];
		searchParts.forEach((part) => {
			// eslint-disable-next-line array-callback-return
			users.filter(data => {
				if (Object.values(data).find(d => d !== null && d.toString().toLowerCase().includes(part.toLowerCase())) !== undefined) {
					const found = result.find(d => d.userId === data.userId);
					if (found === undefined) {
						result.push(data);
					}
				}
			});
		});

		setUsersFiltered(result);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Brugere</div>

			{usersFiltered ?
				<>
					<Typography variant="h5" style={{ marginBottom: 20 }}>Antal brugere: {users.length.toLocaleString('da-DK')}</Typography>

					<Grid container style={{ marginBottom: 20 }}>
						<Grid item xs={12}>
							<Button variant="contained" color="primary" onClick={() => _exportUsers()}>Eksporter brugere</Button>						
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'searchstring'}
								label='Søg'
								value={searchstring}
								onChange={(e) => setSearchstring(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
									  e.preventDefault();
									  search();
									}
								}}
							/>
						</Grid>
					</Grid>

					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Navn</TableCell>
									<TableCell>E-mail</TableCell>
									<TableCell>Alder</TableCell>
									<TableCell>Køn</TableCell>
									<TableCell>Postnr.</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Oprettet</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{usersFiltered.map(user => (
									<TableRow hover key={user.userId} className={classes.tableRow}>
										<TableCell>
											{user.firstname + ' ' + user.lastname}
										</TableCell>
										<TableCell>
											{user.email}
										</TableCell>
										<TableCell>
											{user.age}
										</TableCell>
										<TableCell>
											{user.sex}
										</TableCell>
										<TableCell>
											{user.zip}
										</TableCell>
										<TableCell>
											{renderState(user.state)}
										</TableCell>
										<TableCell>
											{moment(user.created).format('LLL')}
										</TableCell>
										<TableCell align="right">
											<IconButton onClick={() => history.push('/users/edit/' + user.userId)}>
												<EditIcon />
											</IconButton>
											<IconButton onClick={() => validateUser(user.userId)}>
												<CheckIcon />
											</IconButton>
											<IconButton onClick={() => confirmDelete(user.userId)}>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Dialog
						disableEscapeKeyDown
						maxWidth="xs"
						open={showValidateDialog}
					>
						<DialogTitle>Dette vil validere den valgte bruger</DialogTitle>
						<DialogContent dividers>
							Er du sikker?
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={() => handleCancel()} color="primary">
								Nej
							</Button>
							<Button onClick={() => handleValidateOk()} color="primary">
								Ja
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						disableEscapeKeyDown
						maxWidth="xs"
						open={showDeleteDialog}
					>
						<DialogTitle>Dette vil slette den valgte bruger</DialogTitle>
						<DialogContent dividers>
							Er du sikker?
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={() => handleCancel()} color="primary">
								Nej
							</Button>
							<Button onClick={() => handleDeleteOk()} color="primary">
								Ja
							</Button>
						</DialogActions>
					</Dialog>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default UserList;