import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { getStats, getUsers, exportUserStats } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const StatsUsers = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [stats, setStats] = useState([]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const statsData = await getStats();

			if (statsData) {
				setStats(statsData);

				const usersData = await getUsers();

				if (usersData) {
					setUsers(usersData);
				}

				setLoading(false);
			}
		}

		fetchData();
	}, []);

	const _exportUserStats = async () => {
		const fileDownload = require('js-file-download');

		const data = await exportUserStats();

		if (data) {
			fileDownload(data, 'userstats.csv');
		}
	}

	const goToUserStats = (userId) => {
		history.push('/statsgifts/' + userId);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Brugerstatistik</Typography>

			{!loading ?
				<>
					<Button variant="contained" color="primary" onClick={() => _exportUserStats()} style={{ marginBottom: 20 }}>Eksporter</Button>						

					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Bruger</TableCell>
									<TableCell>Logins</TableCell>
									<TableCell>App åbninger</TableCell>
									<TableCell>Handlinger</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(stats['user']).map(key => {
									const user = users.find(u => u.userId === key);

									return <TableRow hover key={key} className={classes.tableRow}>
										<TableCell>{user !== undefined ? user.firstname + ' ' + user.lastname : 'Slettet bruger'}</TableCell>
										<TableCell>{stats['user'][key].login}</TableCell>
										<TableCell>{stats['user'][key].app_open}</TableCell>
										<TableCell><IconButton onClick={() => goToUserStats(key)}><VisibilityIcon /></IconButton></TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
				: <CircularLoader fill />}
		</Paper>
	)
}

export default StatsUsers;