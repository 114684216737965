import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainContainer from 'components/MainContainer';
import Login from './Login';

const Main = () => {
	return (
		<>
			{/* <NewContent /> */}
			<Switch>
				<Route path={'/login'}>
					<Login />
				</Route>
				<Route path={'/'}>
					<MainContainer />
				</Route>
			</Switch>
		</>
	)
}

export default Main;