import React, { useState, useRef } from 'react';
import { TextField, Paper, Button, Grid, Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { addCompany } from 'data/api';
import { toBase64 } from 'lib/functions';

const CompanyAdd = () => {
	const classes = mainStyles();
	const history = useHistory();

	const fileInput = useRef();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [logo, setLogo] = useState('');
	const [url, setUrl] = useState('');
	const [showOnList, setShowOnList] = useState(true);

	const back = () => {
		history.push('/companies/list');
	}

	const add = async () => {
		const postData = {
			name: name,
			description: description,
			logo: logo,
			url: url,
			showOnList: showOnList ? 1 : 0,
		}

		const result = await addCompany(postData);

		if (!result) {
			alert('Der opstod en fejl');
		} else {
			history.push('/companies/list');
		}
	}

	const handleUpload = async (event) => {
		if (event.target.files.length) {
			const logoBase64 = await toBase64(event.target.files[0]);
			setLogo(logoBase64);
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Tilføj firma</Typography>

			<Grid container>
				<Grid item xs={12}>
					<TextField
						id={'name'}
						label='Firmanavn'
						value={name}
						onChange={(e) => setName(e.target.value)}
						margin='normal'
						variant='outlined'
						className={classes.textField}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id={'description'}
						label='Beskrivelse'
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						margin='normal'
						variant='outlined'
						className={classes.textField}
						multiline
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12}>
							{logo.length ? <img src={logo} alt={'logo'} style={{ width: 200, height: 200 }} /> : <></>}
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => fileInput.current.click()}
							>
								Upload logo
							</Button>

							<input
								ref={fileInput}
								type="file"
								accept="image/*"
								hidden
								onChange={handleUpload}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id={'url'}
						label='Hjemmeside'
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						margin='normal'
						variant='outlined'
						className={classes.textField}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormGroup row>
						<FormControlLabel
							control={<Checkbox checked={showOnList} onChange={event => setShowOnList(event.target.checked)} name="showOnList" />}
							label="Vis på lister"
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={() => back()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
					<Button variant="contained" color="primary" onClick={() => add()} style={{ marginTop: 30 }}>Godkend</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default CompanyAdd;