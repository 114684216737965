import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import mainStyles from 'styles/mainStyles';
import CircularLoader from './ui/CircularLoader';
import { getGifts, exportAddresses } from 'data/api';
import { sortBy } from 'lib/functions';

const AddressExport = () => {
	const classes = mainStyles();

	const [loading, setLoading] = useState(true);
	const [gifts, setGifts] = useState([]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const giftData = await getGifts();

			if (giftData) {
				let gifts = [];
				giftData.forEach(gift => {
					if (gift.addressRequired) {
						gifts.push({ giftId: gift.giftId, day: gift.door.day, name: gift.company.name });
					}
				});

				sortBy(gifts, 'day');

				setGifts(gifts);
			}

			setLoading(false);
		}

		fetchData();

	}, []);

	const handleExportAddresses = async (gift) => {
		const fileDownload = require('js-file-download');

		const result = await exportAddresses(gift.giftId);

		if (result.status !== 200) {
			alert('Der er ikke fundet nogle adresser til denne gave.');
			return;
		} else {
			fileDownload(result.data, 'addresser-' + gift.day + '.csv');
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Eksporter adresser</Typography>

			{!loading ?
				<>
					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Låge</TableCell>
									<TableCell>Firma</TableCell>
									<TableCell>Handlinger</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{gifts.map(gift => (
									<TableRow hover key={gift.giftId} className={classes.tableRow}>
										<TableCell>
											{gift.day}
										</TableCell>
										<TableCell>
											{gift.name}
										</TableCell>
										<TableCell>
											<IconButton onClick={() => handleExportAddresses(gift)}><GetAppIcon /></IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
				: <CircularLoader fill />}
		</Paper>
	)
}

export default AddressExport;