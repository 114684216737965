import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';

import mainStyles from 'styles/mainStyles';
import { getCharities } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const CharitiesList = () => {
	const classes = mainStyles();

	const [loading, setLoading] = useState(true);
	const [charities, setCharities] = useState(null);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const charitiesData = await getCharities();

			if (charitiesData) {
				setCharities(charitiesData);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Velgørenhed</Typography>

			{!loading ?
				<>
					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Navn</TableCell>
									<TableCell>Stemmer</TableCell>
									<TableCell>Hjemmeside</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{charities.map(charity => (
									<TableRow hover key={charity.uuid} className={classes.tableRow}>
										<TableCell>
											{charity.name}
										</TableCell>
										<TableCell>
											{charity.votes}
										</TableCell>
										<TableCell>
											{charity.url}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default CharitiesList;