import React, { useState } from 'react';
import { TextField, Paper, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { addVouchers } from 'data/api';

const VoucherAdd = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [count, setCount] = useState('');
	const [description, setDescription] = useState('');
	const [countError, setCountError] = useState('');

	const back = () => {
		history.push('/vouchers/list');
	};

	const add = async () => {
		setCountError('');

		if (!Number.isInteger(parseInt(count))) {
			setCountError('Må kun indeholde et heltal');
		} else {
			const fileDownload = require('js-file-download');

			const vouchers = await addVouchers(count, description);

			if (vouchers) {
				fileDownload(vouchers.join('\n'), 'vouchers.txt');
			}

			setCount('');
			setDescription('');
		}
	};

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Tilføj vouchers</div>

			<Grid container>
				<Grid item xs={12}>
					<TextField
						id={'count'}
						label='Antal koder'
						value={count}
						onChange={(e) => setCount(e.target.value)}
						margin='normal'
						variant='outlined'
						error={countError.length ? true : false}
						helperText={countError}
						className={classes.textField}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id={'description'}
						label='Beskrivelse'
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						margin='normal'
						variant='outlined'
						className={classes.textField}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={() => back()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
					<Button variant="contained" color="primary" onClick={() => add()} style={{ marginTop: 30 }}>Godkend</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default VoucherAdd;