import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import { getStats, getStatsForUser, getGifts, exportGiftStats, getUsers } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const StatsGifts = () => {
	const classes = mainStyles();

	const { userId } = useParams();

	const [loading, setLoading] = useState(true);
	const [stats, setStats] = useState([]);
	const [gifts, setGifts] = useState([]);
	const [user, setUser] = useState(null);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			let statsData = null;

			if (userId === undefined) {
				statsData = await getStats();
			} else {
				statsData = await getStatsForUser(userId);

				const userData = await getUsers();

				if (userData) {
					const user = userData.find(user => user.userId === userId);
					setUser(user);
				}
			}

			if (statsData) {
				setStats(statsData);

				const giftsData = await getGifts();

				if (giftsData) {
					setGifts(giftsData);
				}

				setLoading(false);
			}
		}

		fetchData();
	}, [userId]);

	const _exportGiftStats = async () => {
		const fileDownload = require('js-file-download');

		const data = await exportGiftStats();

		if (data) {
			fileDownload(data, 'giftstats.csv');
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Gavestatistik</Typography>

			{!loading ?
				<>
					{userId === undefined ? <Button variant="contained" color="primary" onClick={() => _exportGiftStats()} style={{ marginBottom: 20 }}>Eksporter</Button> : null}

					{user ? <Typography variant="h5" style={{ marginBottom: 20 }}>Statistik for brugeren: {user.firstname} {user.lastname}</Typography> : null}

					{stats['gift'] !== undefined ?
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="buildings table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Låge</TableCell>
										<TableCell>Åbninger</TableCell>
										<TableCell>Indløsninger</TableCell>
										<TableCell>Delinger</TableCell>
										<TableCell>Hjemmeside</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{gifts.map(gift => {
										const s = stats['gift'][gift.giftId];
										if (s !== undefined) {
											return <TableRow hover key={gift.giftId} className={classes.tableRow}>
												<TableCell>{gift.door.day} - {gift.company.name}</TableCell>
												<TableCell>{s.open ? s.open : 0}</TableCell>
												<TableCell>{s.redeem ? s.redeem : 0}</TableCell>
												<TableCell>{s.share ? s.share : 0}</TableCell>
												<TableCell>{s.homepage ? s.homepage : 0}</TableCell>
											</TableRow>
										} else {
											return <TableRow hover key={gift.giftId} className={classes.tableRow}>
												<TableCell>{gift.door.day} - {gift.company.name}</TableCell>
												<TableCell>0</TableCell>
												<TableCell>0</TableCell>
												<TableCell>0</TableCell>
												<TableCell>0</TableCell>
											</TableRow>
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
						: <Typography>Ingen gave åbninger</Typography>}
				</>
				: <CircularLoader fill />}
		</Paper>
	)
}

export default StatsGifts;