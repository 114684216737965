import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AppsIcon from '@material-ui/icons/Apps';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import HomeIcon from '@material-ui/icons/Home';

import mainStyles from 'styles/mainStyles';

const Menu = () => {
	const classes = mainStyles();
	const history = useHistory();
	const location = useLocation();

	const [activeMenuItem, setActiveMenuItem] = useState('');

	useEffect(() => {
		setActiveMenuItem(location.pathname.split('/')[1]);
	}, [location]);

	const goToPage = (page) => {
		history.push(page);
	}

	return (
		<List>
			<ListItem className={activeMenuItem === 'users' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/users/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'users' ? <PeopleIcon className={classes.adminMenuItemIcon} /> : <PeopleIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Brugere" classes={{ primary: activeMenuItem === 'users' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'vouchers' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/vouchers/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'vouchers' ? <ConfirmationNumberIcon className={classes.adminMenuItemIcon} /> : <ConfirmationNumberIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Vouchers" classes={{ primary: activeMenuItem === 'vouchers' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'gifts' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/gifts/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'gifts' ? <CardGiftcardIcon className={classes.adminMenuItemIcon} /> : <CardGiftcardIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Gaver" classes={{ primary: activeMenuItem === 'gifts' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'companies' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/companies/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'companies' ? <BusinessIcon className={classes.adminMenuItemIcon} /> : <BusinessIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Firmaer" classes={{ primary: activeMenuItem === 'companies' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'notifications' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/notifications/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'notifications' ? <NotificationsIcon className={classes.adminMenuItemIcon} /> : <NotificationsIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Notifikationer" classes={{ primary: activeMenuItem === 'notifications' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'scratchcards' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/scratchcards/list')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'scratchcards' ? <AppsIcon className={classes.adminMenuItemIcon} /> : <AppsIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Skrabelod" classes={{ primary: activeMenuItem === 'scratchcards' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'statsgifts' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/statsgifts')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'statsgifts' ? <EqualizerIcon className={classes.adminMenuItemIcon} /> : <EqualizerIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Gavestatistik" classes={{ primary: activeMenuItem === 'statsgifts' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'statsusers' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/statsusers')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'statsusers' ? <EqualizerIcon className={classes.adminMenuItemIcon} /> : <EqualizerIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Brugerstatistik" classes={{ primary: activeMenuItem === 'statsusers' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'charities' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/charities')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'charities' ? <DeviceHubIcon className={classes.adminMenuItemIcon} /> : <DeviceHubIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Velgørenhed" classes={{ primary: activeMenuItem === 'charities' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>

			<ListItem className={activeMenuItem === 'addresses' ? classes.adminMenuItemActive : classes.adminMenuItem} onClick={() => goToPage('/addresses')}>
				<ListItemIcon style={{ minWidth: 45 }}>
					{activeMenuItem === 'addresses' ? <HomeIcon className={classes.adminMenuItemIcon} /> : <HomeIcon className={classes.adminMenuItemIcon} />}
				</ListItemIcon>
				<ListItemText primary="Adresser" classes={{ primary: activeMenuItem === 'addresses' ? classes.adminMenuItemLabelActive : classes.adminMenuItemLabel }} />
			</ListItem>
		</List>
	)
}

export default Menu;