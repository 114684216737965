import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';

import Main from 'routes/Main';
import theme from 'styles/theme';

function App() {
	return (
		<MuiThemeProvider theme={theme()}>
			<Router>
				<Main />
			</Router>
		</MuiThemeProvider>
	);
}

export default App;
