import { createTheme } from '@material-ui/core/styles';

let defaultOptions = {
	theme: {
		light: {
			primary: '#396639',
			secondary: '#191B32',
			hover: '#fff',
			headerColor: '#fff',
			background: '#fff',
			logo: undefined
		}
	}
}

const theme = (options = defaultOptions) => {
	return createTheme({
		palette: {
			primary: {
				main: options.theme.light.primary,
			}, secondary: {
				main: options.theme.light.secondary,
			},
		},
		hover: options.theme.light.hover,
		header: options.theme.light.headerColor,
		sidebar: options.theme.light.sidebarColor,
		background: options.theme.light.background,
		toolbarBackground: options.theme.light.toolbarBackground,
		toolbarIndicator: options.theme.light.toolbarIndicator,
		typography: {
			h1: {
				fontSize: '2.5rem',
				marginBottom: 20,
			},
			h2: {
				fontSize: '2rem',
				fontWeight: 'bold',
			},
			h3: {
				fontSize: '1.8rem',
			},
			h4: {
				fontSize: '1.6rem',
			},
			h5: {
				fontSize: '1.2rem',
			},
			h6: {
				fontSize: '1rem',
			},
			caption: {
				fontSize: '1.1rem',
				color: 'rgba(0, 0, 0, 0.54)',
			},
			body2: {
				color: '#000',
				fontSize: '1.2rem',
			},
		},
		overrides: {
			MuiDialogTitle: {
				root: {
					fontSize: "1.25rem",
					fontFamily: "Roboto, Helvetica, Arial",
					fontWeight: 500,
					lineHeight: 1.6,
					letterSpacing: "0.0075em",
					backgroundColor: '#5D6A70',
					color: 'white',
					padding: 16
				},

			},
		}
	});
}

export default theme;