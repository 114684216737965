import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/da';

import mainStyles from 'styles/mainStyles';
import { getGiftCodes, getUsers } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const GiftcodesList = () => {
	const classes = mainStyles();
	const { giftId } = useParams();

	const order = 'desc';
	const orderBy = 'used';

	const [loading, setLoading] = useState(true);
	const [giftcodes, setGiftcodes] = useState([]);
	const [users, setUsers] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	// const [selectedUuid, setSelectedUuid] = useState(null);
	// const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const giftcodeData = await getGiftCodes(giftId);

			if (giftcodeData) {
				setGiftcodes(giftcodeData);
			}

			const usersData = await getUsers();

			if (usersData) {
				setUsers(usersData);
			}

			setLoading(false);
		}

		fetchData();
	}, [giftId]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Gavekoder</div>

			{!loading ?
				<>
					<Typography variant="h5" style={{ marginBottom: 20 }}>Antal koder: {giftcodes.length.toLocaleString('da-DK')}</Typography>

					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Kode</TableCell>
									<TableCell>Brugt</TableCell>
									<TableCell>Brugt af</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(giftcodes, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(giftcode => {
										const user = giftcode.usedBy ? users.find(u => u.userId === giftcode.usedBy) : null;
										return <TableRow hover key={giftcode.codeId} className={classes.tableRow}>
											<TableCell>
												{giftcode.code}
											</TableCell>
											<TableCell>
												{giftcode.used ? moment(giftcode.used).format('LLL') : ''}
											</TableCell>
											<TableCell>
												{user ? user.firstname + ' ' + user.lastname : ''}
											</TableCell>
										</TableRow>
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[100, 200, 1000]}
						component="div"
						count={giftcodes.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Rækker per side"
						nextIconButtonText="Næste side"
						backIconButtonText="Forrige side"
					/>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default GiftcodesList;