import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Grid, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import { getGift, getCompanies, updateGift } from 'data/api';
import CircularLoader from './ui/CircularLoader';

const GiftEdit = (props) => {
	const classes = mainStyles();
	const history = useHistory();
	const { giftId } = useParams()

	const [loading, setLoading] = useState(true);
	const [description, setDescription] = useState('');
	const [conditions, setConditions] = useState('');
	const [company, setCompany] = useState('');
	const [door, setDoor] = useState('');
	const [slideToRedeem, setSlideToRedeem] = useState(false);
	const [hasFile, setHasFile] = useState(false);
	const [addressRequired, setAddressRequired] = useState(false);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const data = await getGift(giftId);

			if (data) {
				setDescription(data.description);
				setConditions(data.conditions);
				setCompany(data.company.companyId);
				setDoor(data.door.day);
				setSlideToRedeem(data.slideToRedeem === 1 ? true : false);
				setHasFile(data.hasFile === 1 ? true : false);
				setAddressRequired(data.addressRequired === 1 ? true : false);

				const companiesData = await getCompanies();

				if (companiesData) {
					setCompanies(companiesData);
				}

				setLoading(false);
			}
		}

		fetchData();
	}, [giftId]);

	const handleBack = () => {
		history.push('/gifts/list');
	};

	const handleUpdate = async () => {
		const postData = {
			giftId: giftId,
			description: description,
			conditions: conditions,
			company: company,
			door: door,
			slideToRedeem: slideToRedeem ? 1 : 0,
			hasFile: hasFile ? 1 : 0,
			addressRequired: addressRequired ? 1 : 0
		}

		const result = await updateGift(postData);

		if (!result) {
			alert('Der opstod en fejl');
		} else {
			history.push('/gifts/list');
		}
	};

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Rediger gave</div>

			{!loading ?
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id={'description'}
							label='Beskrivelse'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'conditions'}
							label='Betingelser'
							value={conditions}
							onChange={(e) => setConditions(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="outlined" style={{ marginTop: 10 }}>
							<InputLabel id="door-selectlabel">Låge</InputLabel>
							<Select
								labelId="door-select-label"
								id="door-select"
								value={door}
								onChange={event => setDoor(event.target.value)}
								label="Låge"
								style={{ width: 100 }}
							>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
								<MenuItem value={7}>7</MenuItem>
								<MenuItem value={8}>8</MenuItem>
								<MenuItem value={9}>9</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={11}>11</MenuItem>
								<MenuItem value={12}>12</MenuItem>
								<MenuItem value={13}>13</MenuItem>
								<MenuItem value={14}>14</MenuItem>
								<MenuItem value={15}>15</MenuItem>
								<MenuItem value={16}>16</MenuItem>
								<MenuItem value={17}>17</MenuItem>
								<MenuItem value={18}>18</MenuItem>
								<MenuItem value={19}>19</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={21}>21</MenuItem>
								<MenuItem value={22}>22</MenuItem>
								<MenuItem value={23}>23</MenuItem>
								<MenuItem value={24}>24</MenuItem>
								<MenuItem value={25}>25</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="outlined" style={{ marginTop: 10 }}>
							<InputLabel id="company-selectlabel">Firma</InputLabel>
							<Select
								labelId="company-select-label"
								id="company-select"
								value={company}
								onChange={event => setCompany(event.target.value)}
								label="Firma"
								// style={{ width: 100 }}
							>
								{companies.map(company => (
									<MenuItem value={company.companyId} key={company.companyId}>{company.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={slideToRedeem} onChange={event => setSlideToRedeem(event.target.checked)} name="slideToRedeem" />}
								label="Swipe for at indløse"
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={hasFile} onChange={event => setHasFile(event.target.checked)} name="hasFile" />}
								label="Har download"
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={addressRequired} onChange={event => setAddressRequired(event.target.checked)} name="addressRequired" />}
								label="Adresse krævet"
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" onClick={() => handleBack()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
						<Button variant="contained" color="primary" onClick={() => handleUpdate()} style={{ marginTop: 30 }}>Godkend</Button>
					</Grid>
				</Grid>
				: <CircularLoader />}
		</Paper>
	);
}

export default GiftEdit;