import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';

import mainStyles from 'styles/mainStyles';
import { updateNotification, getNotification } from 'data/api';
import CircularLoader from './ui/CircularLoader';

const NotificationAdd = (props) => {
	const classes = mainStyles();
	const history = useHistory();
	const { uuid } = useParams();

	const [loading, setLoading] = useState(true);
	const [notification, setNotification] = useState(null);
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [run, setRun] = useState(moment().format('YYYY-MM-DDTHH:mm'));

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const notificationData = await getNotification(uuid);
			console.log(notificationData);
			if (notificationData) {
				setNotification(notificationData);
				setTitle(notificationData.title);
				setBody(notificationData.body);
				setRun(moment(notificationData.run).format('YYYY-MM-DDTHH:mm'));

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	const handleBack = () => {
		history.push('/notifications/list');
	};

	const handleAdd = async () => {
		const postData = { ...notification };
		postData.notificationId = uuid;
		postData.title = title;
		postData.body = body;
		postData.run = run;

		const result = await updateNotification(postData);
		console.log(result);

		if (!result) {
			alert('Der opstod en fejl');
		} else {
			history.push('/notifications/list');
		}
	};

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Rediger notifikation</div>

			<Grid container>

				{!loading ?
					<>
						<Grid item xs={12}>
							<TextField
								id={'title'}
								label='Titel'
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
								inputProps={{
									maxLength: 39,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={'body'}
								label='Tekst'
								value={body}
								onChange={(e) => setBody(e.target.value)}
								margin='normal'
								variant='outlined'
								className={classes.textField}
								multiline
								inputProps={{
									maxLength: 150,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant='outlined'
								id="datetime-local"
								label="Udsendingstidspunkt"
								type="datetime-local"
								value={run}
								onChange={(e) => setRun(e.target.value)}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ marginTop: 20 }}
							/>
						</Grid>
					</>
					: <CircularLoader />}

				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={() => handleBack()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
					<Button variant="contained" color="primary" onClick={() => handleAdd()} style={{ marginTop: 30 }}>Godkend</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default NotificationAdd;