import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import 'moment/locale/da';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { getVouchers, deleteVoucher, getUsers, exportVouchers } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';

const VoucherList = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [vouchers, setVouchers] = useState(null);
	const [vouchersFiltered, setVouchersFiltered] = useState(null);
	const [searchstring, setSearchstring] = useState('');
	const [users, setUsers] = useState([]);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			const data = await getVouchers();

			if (data) {
				setVouchers(data);
				setVouchersFiltered(data);

				const usersData = await getUsers();

				if (usersData) {
					setUsers(usersData);
				}
			}
		}

		fetchData();
	}, []);

	const addVouchers = () => {
		history.push('/vouchers/add');
	};

	const sendVouchers = () => {
		history.push('/vouchers/send');
	};

	const confirmDelete = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	};

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		const status = await deleteVoucher(selectedUuid);

		if (status !== 200) {
			alert('Der opstod en fejl!');
		} else {
			const data = await getVouchers();

			if (data) {
				setVouchers(data);
			}

			setShowDeleteDialog(false);
			setSelectedUuid(null);
		}
	}

	const _exportVouchers = async () => {
		const fileDownload = require('js-file-download');

		const data = await exportVouchers();

		if (data) {
			fileDownload(data, 'vouchers.csv');
		}
	}

	const search = () => {
		let result = [];
		// eslint-disable-next-line array-callback-return
		vouchers.filter(data => {
			if (JSON.stringify(data).toLowerCase().search(searchstring) > -1) {
				result.push(data);
			}
		});

		setVouchersFiltered(result);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Vouchers</div>

			<Grid container style={{ marginBottom: 20 }}>
				<Grid item xs={12}>
					<div style={{ marginBottom: 20 }}>
						<Button variant="contained" color="primary" onClick={() => addVouchers()} style={{ marginRight: 20 }}>Tilføj vouchers</Button>
						<Button variant="contained" color="primary" onClick={() => sendVouchers()} style={{ marginRight: 20 }}>Send voucher</Button>
						<Button variant="contained" color="primary" onClick={() => _exportVouchers()}>Eksporter vouchers</Button>
					</div>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id={'searchstring'}
						label='Søg'
						value={searchstring}
						onChange={(e) => setSearchstring(e.target.value)}
						margin='normal'
						variant='outlined'
						className={classes.textField}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								search();
							}
						}}
					/>
				</Grid>
			</Grid>

			{vouchersFiltered ?
				<>
					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Kode</TableCell>
									<TableCell>Beskrivelse</TableCell>
									<TableCell>Oprettet</TableCell>
									<TableCell>Brugt</TableCell>
									<TableCell>Brugt af</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{vouchersFiltered.map(voucher => {
									const user = voucher.usedBy ? users.find(u => u.userId === voucher.usedBy) : null;

									return (
										<TableRow hover key={voucher.voucherId} className={classes.tableRow}>
											<TableCell>
												{voucher.voucherCode}
											</TableCell>
											<TableCell>
												{voucher.description}
											</TableCell>
											<TableCell>
												{moment(voucher.created).format('LLL')}
											</TableCell>
											<TableCell>
												{voucher.used ? moment(voucher.used).format('LLL') : ''}
											</TableCell>
											<TableCell>
												{user ? user.firstname + ' ' + user.lastname : ''}
											</TableCell>
											<TableCell align="right">
												{/* <IconButton onClick={() => history.push('/vouchers/edit')}>
													<EditIcon />
												</IconButton> */}
												<IconButton onClick={() => confirmDelete(voucher.voucherId)}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									)}
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<Dialog
						disableEscapeKeyDown
						maxWidth="xs"
						open={showDeleteDialog}
					>
						<DialogTitle>Dette vil slette den valgte voucher</DialogTitle>
						<DialogContent dividers>
							Er du sikker?
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={handleCancel} color="primary">
								Nej
							</Button>
							<Button onClick={handleOk} color="primary">
								Ja
							</Button>
						</DialogActions>
					</Dialog>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default VoucherList;