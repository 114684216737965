import React, { useState, useRef, useEffect } from 'react';
import { TextField, Paper, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import mainStyles from 'styles/mainStyles';
import { getScratchCard, updateScratchCard } from 'data/api';
import { toBase64 } from 'lib/functions';
import CircularLoader from './ui/CircularLoader';

const ScratchCardEdit = (props) => {
	const classes = mainStyles();
	const history = useHistory();
	const { uuid } = useParams()

	const winnerImageInput = useRef();
	const looserImageInput = useRef();
	const foregroundImageInput = useRef();

	const [loading, setLoading] = useState(true);
	const [scratchCard, setScratchCard] = useState(null);
	const [text, setText] = useState('');
	const [winnerImage, setWinnerImage] = useState('');
	const [looserImage, setLooserImage] = useState('');
	const [foregroundImage, setForegroundImage] = useState('');
	const [date, setDate] = useState('');
	const [zipCodes, setZipCodes] = useState('');
	const [numWinners, setNumWinners] = useState('');

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const scData = await getScratchCard(uuid);

			if (scData) {
				setScratchCard(scData);
				setText(scData.text);
				setWinnerImage(scData.winnerImage);
				setLooserImage(scData.looserImage);
				setForegroundImage(scData.foregroundImage);
				setDate(moment(scData.date).format('YYYY-MM-DD'));
				setZipCodes(scData.zipCodes);
				setNumWinners(scData.numWinners);

				setLoading(false);
			}
		}

		fetchData();
	}, [uuid]);

	const handleBack = () => {
		history.push('/scratchcards/list');
	};

	const handleUpdate = async () => {
		const postData = { ...scratchCard };
		postData.text = text;
		postData.winnerImage = winnerImage;
		postData.looserImage = looserImage;
		postData.foregroundImage = foregroundImage;
		postData.date = date;
		postData.zipCodes = zipCodes;
		postData.numWinners = numWinners;

		const result = await updateScratchCard(postData);

		if (!result) {
			alert('Der opstod en fejl');
		} else {
			history.push('/scratchcards/list');
		}
	};

	const handleWinnerImage = async (event) => {
		if (event.target.files.length) {
			const winnerImageBase64 = await toBase64(event.target.files[0]);
			setWinnerImage(winnerImageBase64);
		}
	}

	const handleLooserImage = async (event) => {
		if (event.target.files.length) {
			const looserImageBase64 = await toBase64(event.target.files[0]);
			setLooserImage(looserImageBase64);
		}
	}

	const handleForegroundImage = async (event) => {
		if (event.target.files.length) {
			const foregroundImageBase64 = await toBase64(event.target.files[0]);
			setForegroundImage(foregroundImageBase64);
		}
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Rediger skrabelod</div>

			{!loading ? (
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id={'text'}
							label='Vinder tekst'
							value={text}
							onChange={(e) => setText(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								{winnerImage.length ? <img src={winnerImage} alt={''} style={{ width: 200, height: 200, marginTop: 20 }} /> : <></>}
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => winnerImageInput.current.click()}
								>
									Upload vinder billede
								</Button>

								<input
									ref={winnerImageInput}
									type="file"
									accept="image/*"
									hidden
									onChange={handleWinnerImage}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								{looserImage.length ? <img src={looserImage} alt={''} style={{ width: 200, height: 200, marginTop: 20 }} /> : <></>}
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => looserImageInput.current.click()}
									style={{ marginTop: 10 }}
								>
									Upload taber billede
								</Button>

								<input
									ref={looserImageInput}
									type="file"
									accept="image/*"
									hidden
									onChange={handleLooserImage}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								{foregroundImage.length ? <img src={foregroundImage} alt={''} style={{ width: 200, height: 200, marginTop: 20 }} /> : <></>}
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => foregroundImageInput.current.click()}
									style={{ marginTop: 10 }}
								>
									Upload foregrounds billede
								</Button>

								<input
									ref={foregroundImageInput}
									type="file"
									accept="image/*"
									hidden
									onChange={handleForegroundImage}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							id="date"
							label="Dato"
							type="date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							style={{ marginTop: 20 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'zipCodes'}
							label='Vinder postnumre'
							value={zipCodes}
							onChange={(e) => setZipCodes(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={'numWinners'}
							label='Mulige vindere'
							value={numWinners}
							onChange={(e) => setNumWinners(e.target.value)}
							margin='normal'
							variant='outlined'
							className={classes.textField}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" onClick={() => handleBack()} style={{ marginTop: 30, marginRight: 10 }}>Tilbage</Button>
						<Button variant="contained" color="primary" onClick={() => handleUpdate()} style={{ marginTop: 30 }}>Godkend</Button>
					</Grid>
				</Grid>
			) : <CircularLoader />}
		</Paper>
	);
}

export default ScratchCardEdit;