import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, ButtonGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';

import mainStyles from 'styles/mainStyles';
import { deleteNotification, getNotifications } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import moment from 'moment';

const NotificationsList = () => {
	const classes = mainStyles();
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [notifications, setNotifications] = useState(null);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const data = await getNotifications();

			if (data) {
				setNotifications(data);

				setLoading(false);
			}
		}

		fetchData();
	}, []);

	const confirmDelete = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	};

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		setLoading(true);

		const status = await deleteNotification(selectedUuid);

		if (status !== 200) {
			alert('Der opstod en fejl!');
		} else {
			const data = await getNotifications();

			if (data) {
				setNotifications(data);
			}

			setShowDeleteDialog(false);
			setSelectedUuid(null);

			setLoading(false);
		}
	}

	const handleAdd = () => {
		history.push('/notifications/add');
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<Typography variant="h1">Notifikationer</Typography>

			<Button variant="contained" color="primary" onClick={() => handleAdd()} style={{ marginBottom: 20 }}>Tilføj notifikation</Button>

			{!loading ?
				<>
					<TableContainer component={Paper}>
						<Table stickyHeader className={classes.table} aria-label="buildings table">
							<TableHead>
								<TableRow className={classes.tableRow}>
									<TableCell>Titel</TableCell>
									<TableCell>Besked</TableCell>
									<TableCell>Tidspunkt</TableCell>
									<TableCell>Sendt</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{notifications.map(notification => (
									<TableRow hover key={notification.notificationId} className={classes.tableRow}>
										<TableCell>
											{notification.title}
										</TableCell>
										<TableCell>
											{notification.body}
										</TableCell>
										<TableCell>
											{moment(notification.run).format('LLL')}
										</TableCell>
										<TableCell>
											{notification.sent === 0 ? 'Nej' : 'Ja'}
										</TableCell>
										<TableCell align="right">
											<ButtonGroup>
												<IconButton onClick={() => history.push('/notifications/edit/' + notification.notificationId)}>
													<EditIcon />
												</IconButton>
												<IconButton onClick={() => confirmDelete(notification.notificationId)}>
													<DeleteIcon />
												</IconButton>

											</ButtonGroup>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Dialog
						disableEscapeKeyDown
						maxWidth="xs"
						open={showDeleteDialog}
					>
						<DialogTitle>Dette vil slette den valgte notifikation</DialogTitle>
						<DialogContent dividers>
							Er du sikker?
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={handleCancel} color="primary">
								Nej
							</Button>
							<Button onClick={handleOk} color="primary">
								Ja
							</Button>
						</DialogActions>
					</Dialog>
				</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default NotificationsList;