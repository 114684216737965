import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import cookie from 'react-cookies';

import loginStyles from 'styles/loginStyles';
import { login, setToken } from 'data/api';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [error, setError] = useState(false);

	const classes = loginStyles();
	const location = useLocation();
	const history = useHistory();

	const handleLogin = async () => {
		setLoggingIn(true);

		await login(username, password).then(async rs => {
			if (rs) {
				let exp = moment().add('30', 'days');
				cookie.save('SESSION', rs, { path: '/', expires: exp.toDate() });

				if (setToken()) {
					var prevURL = location.state ? location.state.prevURL : null;
					history.push(prevURL ? prevURL : '/');
				}
			} else {
				setError(true);
				setLoggingIn(false);
			}
		});
	}

	return (
		<div className={classes.background}>
			<div className={classes.container}>
				{!loggingIn ?
					<>
						<TextField
							id="username"
							value={username}
							autoFocus
							onChange={(event) => setUsername(event.target.value)}
							className={classes.textfield}
							label="Brugernavn"
							variant="outlined"
							error={error}
						/>

						<TextField
							id="password"
							type="password"
							onChange={(event) => setPassword(event.target.value)}
							className={classes.textfield}
							label="Kodeord"
							variant="outlined"
							error={error}
						/>

						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							disabled={loggingIn}
							onClick={handleLogin}>
							LOG IND
						</Button>
					</>
					: <CircularProgress size={50} className={classes.buttonProgress} />
				}
			</div>
		</div>
	)
}

export default Login;
