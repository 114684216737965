import { makeStyles } from '@material-ui/styles';

const loginStyles = makeStyles(theme => ({
	background: {
		width: '100%',
		height: '100vh',
		backgroundColor: '#396639',
	},
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-125px',
		marginLeft: '-250px',
		width: '500px',
		height: '250px',
		backgroundColor: '#eee',
		textAlign: 'center',
		boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.49)',
		borderRadius: '4px',
	},
	textfield: {
		width: '80%',
		marginTop: '20px'
	},
	button: {
		width: '80%',
		height: '50px',
		marginTop: '20px',
		fontSize: '18px',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-25px',
		marginLeft: '-25px',
	},
}));

export default loginStyles;