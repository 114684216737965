import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@material-ui/core';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { useHistory } from 'react-router';
import Gravatar from 'react-gravatar';
import cookie from 'react-cookies';

import mainStyles from 'styles/mainStyles';
import { getLoggedInUser, logout } from 'data/api';

const Header = (props) => {
	const classes = mainStyles();
	const history = useHistory();

	const [user, setUser] = useState(null);
	const [anchorProfile, setAnchorProfile] = useState(null);
	const openProfile = Boolean(anchorProfile);

	useEffect(() => {
		async function fetchData() {
			const data = await getLoggedInUser();

			if (!data) {
				await handleLogOut();
			} else {
				setUser(data);
			}
		}

		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleProfileOpen = e => {
		setAnchorProfile(e.currentTarget);
	};

	const handleProfileClose = () => {
		setAnchorProfile(null);

		if (props.onClose) {
			props.onClose();
		}
	};

	const handleLogOut = async () => {
		await logout();
		cookie.remove('SESSION');

		history.push('/login');
	};

	// const handleLogoClick = () => {
	// 	history.push('/');
	// }

	return (
		<>
			<div className={classes.appBarWrapper}>
				<AppBar className={classes.appBarPrimary} position='relative' elevation={0}>
					<Toolbar>
						<div className={classes.logoContainer} edge="start">
							{/* <ButtonBase
								focusRipple
								className={classes.image}
								focusVisibleClassName={classes.focusVisible}
								onClick={handleLogoClick}
							>
								{wl && wl.logo ? <img src={wl.logo} alt="" className={classes.logo} /> : <LogoIcon className={classes.logo} />}
							</ButtonBase> */}
						</div>

						<Typography className={classes.appbarTitle}>
							Din Julekalender - Administration
						</Typography>

						<div>
							<div className={classes.username}>{user ? user.firstname + ' ' + user.lastname : ""}</div>
							<Button
								onClick={handleProfileOpen}
							>
								{user ? user.img ? <img src={user.img} alt='UserProfile' className={classes.userimage} /> : <Gravatar default='mp' email={user.email} className={classes.userimage} /> : ""}
							</Button>

							<Menu
								style={{ marginTop: 55 }}
								id='menu-appbar'
								anchorEl={anchorProfile}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								open={openProfile}
								onClose={handleProfileClose}
								disableAutoFocusItem
							>
								{/* <MenuItem onClick={() => goToPage('profile')}>
									<AccountBoxIcon className={classes.usermenuIcon} />Min profil
								</MenuItem> */}
								<MenuItem onClick={() => { handleLogOut() }}>
									<PowerSettingsNew className={classes.usermenuIcon} />Log ud
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
			</div>
		</>
	);
}

export default Header;